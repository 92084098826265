.start-marker {
	background-image: url("../../img/starting-point.png");
	background-size: cover;
	border-radius: 50%;
	cursor: pointer;
}

.end-marker {
	background-image: url("../../img/arrival-point.png");
	background-size: cover;
	border-radius: 50%;
	cursor: pointer;
}

.arrow-path {
	stroke: #c00;
	stroke-width: 0.5;
	fill: none;
}

.station:before {
	content: " ";
	display: inline-block;
	width: 8px;
	height: 8px;
	background: red;
	border-radius: 8px;
	margin: 0 8px;
}
.station {
	border-radius: 20px;
	padding-right: 12px;
	margin: -12px;
	color: transparent;
	line-height: 24px;
	font-size: 13px;
	white-space: nowrap;
}
.station span {
	display: none;
}
.station:hover {
	background: rgba(0, 0, 0, 0.8);
	color: #fff;
}
.station:hover span {
	display: inline-block;
}
